import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { IconButton, Tooltip, Chip } from "@material-ui/core";

import { Cancel, Edit, Visibility } from "@material-ui/icons";

import { useDispatch } from "react-redux";

import BroadcastReport from "./BroadcastReport";

import convertDate from "../../../../util/convertDate";
import scheduledDate from "./scheduledDateFormat";

import {
  alertError,
  alertSuccess,
} from "../../../../redux/actions/alertActions";

import { DataTable } from "../../../../custom components";

import { useAxios } from "../../../../custom hooks";
import CancelDialog from "./CancelBroadcastDialogue";

export default function Table({
  broadcasts,
  setCancelBroadcast,
  authToken,
  agentID,
  memberID,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState({
    state: false,
    id: "",
  });

  const axios = useAxios({
    baseURL: "adminV2",
    customAuthToken: "Bearer " + authToken,
  });

  const [broadcastReport, setBroadcastReport] = useState({
    state: false,
    report: {},
    link: "",
  });

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "_id",
      label: "Broadcast ID",
      options: {
        customBodyRender: (value) => {
          return value
            ? value.slice(0, 15) + (value.length > 15 ? "..." : "")
            : "Not Available";
        },
      },
    },
    {
      name: "broadcast_name",
      label: "Broadcast Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "template_name",
      label: "Template Name",
      options: {
        customBodyRender: (value) => {
          return value
            ? value.slice(0, 30) + (value.length > 15 ? "..." : "")
            : "Not Available";
        },
      },
    },
    {
      name: "scheduled_date",
      label: "Scheduled At",
      options: {
        customBodyRender: (value) => {
          return value ? scheduledDate(value) : "Not Available";
        },
      },
    },
    {
      name: "scheduled_time_data",
      label: "Scheduled Time",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "status_id",
      label: "Broadcast Status",
      options: {
        customBodyRender: (value) => {
          if (value) {
            if (value === "a7e117f613ef47338df34c60f3a75d76") {
              return (
                <Chip
                  label={"Success"}
                  style={{ color: "#fff", backgroundColor: "#22af47" }}
                />
              );
            } else if (
              value === "d35ceb22fa0e4c2b964e253062bf3460" ||
              value === "86844d49cfb147ff92b0cae976ec43ec"
            ) {
              return (
                <Chip
                  label={
                    value === "d35ceb22fa0e4c2b964e253062bf3460"
                      ? "Draft"
                      : "Pending"
                  }
                  style={{ color: "#fff", backgroundColor: "#ff9800" }}
                />
              );
            } else if (
              value === "0d84075ce0ba4504b09df1f959ef610a" ||
              value === "23a547fb34eb496a8dd44c09d44b49c0"
            ) {
              return (
                <Chip
                  label={
                    value === "0d84075ce0ba4504b09df1f959ef610a"
                      ? "Failed"
                      : "Cancelled"
                  }
                  style={{ color: "#fff", backgroundColor: "#FF0000" }}
                />
              );
            } else if (value === "a76d72f97f554476ab921c14b64fa788") {
              return (
                <Chip
                  label={"Processing"}
                  style={{ color: "#fff", backgroundColor: "#376fd0" }}
                />
              );
            } else {
              return value;
            }
          } else {
            return "Not Available";
          }
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (rowIndex, index) => (
          <React.Fragment>
            {index.rowData[6] !== "a7e117f613ef47338df34c60f3a75d76" &&
              index.rowData[6] !== "23a547fb34eb496a8dd44c09d44b49c0" &&
              index.rowData[6] !== "a76d72f97f554476ab921c14b64fa788" &&
              index.rowData[6] !== "0d84075ce0ba4504b09df1f959ef610a" &&
              index.rowData[6] !== "86844d49cfb147ff92b0cae976ec43ec" && (
                <IconButton
                  style={{ float: "left" }}
                  onClick={() =>
                    history.push({
                      pathname: "/broadcast-details/" + index.rowData[0],
                      search: "?agent_id=" + agentID + "&member_id=" + memberID,
                    })
                  }
                  color="primary"
                  size="small"
                >
                  <Tooltip title="Edit Broadcast">
                    <Edit />
                  </Tooltip>
                </IconButton>
              )}
            {index.rowData[6] !== "23a547fb34eb496a8dd44c09d44b49c0" &&
              index.rowData[6] !== "a76d72f97f554476ab921c14b64fa788" &&
              index.rowData[6] !== "a7e117f613ef47338df34c60f3a75d76" &&
              index.rowData[6] !== "d35ceb22fa0e4c2b964e253062bf3460" &&
              index.rowData[6] !== "0d84075ce0ba4504b09df1f959ef610a" && (
                <IconButton
                  style={{ float: "left", color: "#ff0000" }}
                  onClick={() =>
                    setDialogState({
                      state: true,
                      id: index.rowData[0],
                    })
                  }
                  color="primary"
                  size="small"
                >
                  <Tooltip title="Cancel Broadcast">
                    <Cancel />
                  </Tooltip>
                </IconButton>
              )}
            <Tooltip title="View Report">
              <IconButton
                style={{ float: "right" }}
                onClick={() => loadReport(index.rowData[0])}
                color="primary"
                size="small"
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ),
      },
    },
  ];

  const loadReport = (id) => {
    axios({
      url: "/broadcast/get/report/" + id,
      method: "GET",
    })
      .then((res) => {
        if (res.status) {
          setBroadcastReport({
            state: true,
            report: res.data,
            download: res.data?.report?.url || "",
          });
        } else {
          dispatch(alertError(res.message));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelBroadcast = () => {
    var data = dialogState.id;
    axios({
      url: "/broadcast/cancel/campaign",
      method: "PATCH",
      data: {
        sbcid: data,
      },
    }).then((res) => {
      if (res.status) {
        dispatch(alertSuccess(res.message));
        setCancelBroadcast(res.data.sbcid);
      } else {
        dispatch(alertError(res.message));
      }
    });
  };

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={broadcasts} />
      {broadcastReport.state && (
        <BroadcastReport
          broadcastReport={broadcastReport}
          setBroadcastReport={setBroadcastReport}
          agentID={agentID}
          authToken={authToken}
        />
      )}
      <CancelDialog
        dialogState={dialogState.state}
        setDialogState={setDialogState}
        handleAgree={cancelBroadcast}
      />
    </React.Fragment>
  );
}
