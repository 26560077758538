import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import {
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  IconButton,
  Tooltip,
  Paper as MuiPaper,
} from "@material-ui/core";

import {
  alertError,
  alertSuccess,
} from "../../../../redux/actions/alertActions";

import scheduledDate from "./scheduledDateFormat";

import { FileCopy, GetApp, Cancel, Sync } from "@material-ui/icons";
import convertDate from "../../../../util/convertDate";
import { titleCase } from "change-case-all";
import styled from "styled-components";
import { useAxios } from "../../../../custom hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
  typographyColor: {
    color: "#9e9e9e !important",
    fontSize: "14px",
    fontWeight: "500",
  },
  fontSize: {
    fontSize: "14px",
    fontWeight: "500",
  },
  success: {
    color: "#4F8A10",
    backgroundColor: "#DFF2BF",
  },
  fail: {
    color: "#D8000C",
    backgroundColor: "#FFD2D2",
  },
  seen: {
    color: "#00529B",
    backgroundColor: "#BDE5F8",
  },
  sent: {
    color: "#9F6000",
    backgroundColor: "#FEEFB3",
  },
  initiated: {
    color: "#0c5460",
    backgroundColor: "#d1ecf1",
  },
}));

const Paper = styled(MuiPaper)`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function BroadcastReport({
  broadcastReport,
  setBroadcastReport,
  authToken,
  agentID,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const axios = useAxios({
    baseURL: "adminV2",
    customAuthToken: "Bearer " + authToken,
  });

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(alertSuccess("copied to clipboard!"));
  };

  const downloadUploadedFile = () => {
    window.location.href = broadcastReport.report.broadcast_url;
  };

  const downloadFile = () => {
    if (!broadcastReport.download) {
      axios({
        url: "/broadcast/download/report/" + broadcastReport.report._id,
        method: "GET",
      })
        .then((res) => {
          if (res.status) {
            dispatch(alertSuccess(res.message.displayMessage));
          } else {
            dispatch(alertError(res.message.displayMessage));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      window.location.href = broadcastReport.download;
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={broadcastReport.state}
      onClose={handleClose}
      fullWidth
    >
      <Grid
        container
        spacing={0}
        style={{
          borderBottom: "1px solid #cbcbcb",
        }}
      >
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h5">Broadcast Report</Typography>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            style={{ float: "right", padding: "16px 24px" }}
            onClick={handleClose}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent style={{ margin: "10px 0" }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={2}>
            <Paper className={classes.initiated}>
              <Typography variant="h1">
                {broadcastReport.report.total_message_initiation}
              </Typography>
              <Typography variant="subtitle1">Messages Initiated</Typography>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper className={classes.sent}>
              <Typography variant="h1">
                {broadcastReport.report.total_no_message_sent}
              </Typography>
              <Typography variant="subtitle1">Messages Sent</Typography>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper className={classes.success}>
              <Typography variant="h1">
                {broadcastReport.report.total_no_message_delivered}
              </Typography>
              <Typography variant="subtitle1">Messages Delivered</Typography>
            </Paper>
          </Grid>

          <Grid item xs={3}>
            <Paper className={classes.seen}>
              <Typography variant="h1">
                {broadcastReport.report.total_no_message_seen}
              </Typography>
              <Typography variant="subtitle1">Messages Seen</Typography>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper className={classes.fail}>
              <Typography variant="h1">
                {broadcastReport.report.total_no_message_failed}
              </Typography>
              <Typography variant="subtitle1">Messages Failed</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} />
          <Grid item xs={3}>
            <Typography className={classes.fontSize}>Broadcast ID</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.typographyColor}>
              {broadcastReport.report._id.slice(0, 15) +
                (broadcastReport.report._id.length > 15 ? "..." : "")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              style={{ padding: "0 12px" }}
              onClick={() => copyText(broadcastReport.report._id)}
            >
              <Tooltip title="Copy broadcast ID">
                <FileCopy />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.fontSize}>Template ID</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.typographyColor}>
              {broadcastReport.report.template_id.slice(0, 15) +
                (broadcastReport.report.template_id.length > 15 ? "..." : "")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              style={{ padding: "0 12px" }}
              onClick={() => copyText(broadcastReport.report.template_id)}
            >
              <Tooltip title="Copy template ID">
                <FileCopy />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.fontSize}>Broadcast Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.typographyColor}>
              {titleCase(broadcastReport.report.broadcast_name)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.fontSize}>Template Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.typographyColor}>
              {broadcastReport.report.template_name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.fontSize}>Created At</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.typographyColor}>
              {convertDate(broadcastReport.report.created_at)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.fontSize}>Scheduled At</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.typographyColor}>
              {scheduledDate(broadcastReport.report.scheduled_date)}
              &nbsp;|&nbsp;
              {broadcastReport.report.scheduled_time}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.fontSize}>Status</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.typographyColor}>
              {titleCase(broadcastReport.report.status)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.fontSize}>Created By</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.typographyColor}>
              {broadcastReport.report.created_by}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.fontSize}>Updated By</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.typographyColor}>
              {broadcastReport.report.updated_by}
            </Typography>
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ padding: "16px 24px", borderTop: "1px solid #cbcbcb" }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadUploadedFile}
          >
            <GetApp /> &nbsp; Download Uploaded Data File
          </Button>
        </Grid>

        <Grid item>
          {!broadcastReport.download ? (
            <Button variant="contained" color="primary" onClick={downloadFile}>
              <Sync /> &nbsp; Generate Report
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={downloadFile}>
              <GetApp /> &nbsp; Download Report
            </Button>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    setBroadcastReport(false);
  }
}
