import channel from "../../../constants/channelID";

export const botDetailsObject = {
  botID: "",
  guestAccessToken: "",
  botName: "",
  businessID: "",
  botChannel: "",
  botServiceID: "",
  botPlanID: "",
  whatsappServer: "normal",
  authentication: {
    accessToken: "",
    adminUsername: "",
    adminPassword: "",
    URL: "",
    whatsappServer: "",
    namespace: "",
    host: "",
    wabaID: "",
    phoneNumber: "",
    secret: "",
    verify: "",
    pageID: "",
    pageName: "",
  },
  totalUsers: "",
  totalConversations: "",
  totalSessions: "",
  botStatus: "",
  createdAt: "",
  updatedAt: "",
  permissions: {
    adminPermissions: {},
    billerPermissions: {},
    managerPermissions: {},
  },
  pagePermissions: [],
};

export function objectFromResponse(responseData) {
  var authentication = {};

  if (responseData.channel_id === channel.whatsapp) {
    authentication = {
      accessToken: responseData.authentication.access_token || "",
      phoneNumber: responseData.authentication.wa_number || "",
      adminUsername: responseData.authentication.admin_username || "",
      adminPassword: responseData.authentication.admin_password || "",
      whatsappServer: responseData.authentication.whatsapp_server || "",
      URL: responseData.authentication.url || "",
      namespace: responseData.authentication.namespace || "",
      host: responseData.authentication.host || "",
      wabaID: responseData.authentication.wabaid || "",
    };
  } else if (
    responseData.channel_id === channel.facebook ||
    responseData.channel_id === channel.instagram
  ) {
    authentication = {
      accessToken: responseData.authentication["page-access-token"] || "",
      pageID: responseData.authentication.page_id || "",
      pageName: responseData.authentication.page_name || "",
      secret: responseData.authentication.secret || "",
      verify: responseData.authentication.verify || "",
    };
  }

  return {
    botID: responseData.agent_id || "",
    guestAccessToken: responseData.access_token || "",
    botName: responseData.agent_name || "Not Available",
    botChannel: responseData.channel_id || "",
    botServiceID: responseData.bot_service_id || "",
    botPlanID: responseData.agent_plan_id || "",
    authentication: authentication,
    whatsappServer: authentication.whatsappServer || "",
    businessID: responseData.business_id || "",
    totalUsers: responseData.total_users || "0",
    totalSessions: responseData.total_session || "0",
    totalConversations: responseData.total_conversations || "0",
    botStatus: responseData.is_active || false,
    createdAt: responseData.created_at,
    updatedAt: responseData.updated_at,
    permissions: {
      adminPermissions: responseData.permission.admin_permissions || {},
      billerPermissions: responseData.permission.biller_permissions || {},
      managerPermissions: responseData.permission.manager_permissions || {},
    },
    pagePermissions: responseData.page_permission || [],
  };
}

export function objectFromFormData(formData) {
  var authentication;

  if (formData.botChannel === channel.whatsapp) {
    authentication = {
      access_token: formData.authentication.accessToken,
      wa_number: formData.authentication.phoneNumber,
      admin_username: formData.authentication.adminUsername,
      admin_password: formData.authentication.adminPassword,
      whatsapp_server: formData.whatsappServer,
      url: formData.authentication.URL,
      wabaid: formData.authentication.wabaID,
      ...(formData.whatsappServer === "normal"
        ? { namespace: formData.authentication.namespace }
        : { host: formData.authentication.host }),
    };
  } else if (
    formData.botChannel === channel.facebook ||
    formData.botChannel === channel.instagram
  ) {
    authentication = {
      "page-access-token": formData.authentication.accessToken,
      page_id: formData.authentication.pageID,
      secret: formData.authentication.secret,
      page_name: formData.authentication.pageName,
      verify: formData.authentication.verify,
    };
  }

  return {
    agent_name: formData.botName,
    channel: formData.botChannel,
    bot_service_id: formData.botServiceID,
    agent_plan_id: formData.botPlanID,
    authentication: authentication,
    business_id: formData.businessID,
    is_active: formData.botStatus,
    permission: {
      admin_permissions: formData.permissions.adminPermissions,
      biller_permissions: formData.permissions.billerPermissions,
      manager_permissions: formData.permissions.managerPermissions,
    },
    page_permission: formData.pagePermissions,
  };
}
