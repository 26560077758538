import React, { useState, useEffect } from "react";

import Form from "./Form";

import queryString from "query-string";

import { alertError } from "../../../../redux/actions/alertActions";

import { useLoader, useAxiosV2 } from "../../../../custom hooks";
import { PageHeader } from "../../../../custom components";

import {
  broadcastDetailsObject,
  objectFromResponse,
} from "./broadcastDetailsObject";

import { apiComplete } from "../../../../redux/actions/loaderActions";
import { useDispatch } from "react-redux";

export default function BroadcastDetails(props) {
  const dispatch = useDispatch();
  const loader = useLoader();

  const agentID = queryString.parse(window.location.search).agent_id || "";

  const memberID = queryString.parse(window.location.search).member_id || "";
  localStorage.setItem("member_id", memberID);
  localStorage.setItem("agent_id", agentID);

  const accessAxios = useAxiosV2({
    baseURL: "adminV2",
    disableAuthToken: true,
  });

  const axios = useAxiosV2({
    baseURL: "adminV2",
  });

  const broadcastID = props.match.params.broadcastID || "";

  const [formData, setFormData] = useState(broadcastDetailsObject);
  const [authToken, setAuthToken] = useState("");
  const [template, setTemplate] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [templateData, setTemplateData] = useState({});
  const [scheduledTime, setScheduledTime] = useState([]);

  useEffect(() => {
    loader.start(4);
    accessAxios({
      url: "/token/" + memberID,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setAuthToken(response.data.token);
        axios({
          url: "/wa/template/" + agentID + "?is_active=1&verified_status=3",
          method: "GET",
          authToken: "Bearer " + response.data.token,
        })
          .then((response) => {
            if (response.status) {
              apiComplete(1);
              setTemplate(response.data.temp_obj);
            } else {
              dispatch(alertError(response.message.displayMessage));
            }
          })
          .catch((error) => {
            console.log(error);
          });

        axios({
          url: "/broadcast/get/group/" + agentID,
          method: "GET",
          authToken: "Bearer " + response.data.token,
        })
          .then((response) => {
            if (response.status) {
              apiComplete(1);
              setGroupList(response.data);
            } else {
              dispatch(alertError(response.message.displayMessage));
            }
          })
          .catch((error) => {
            console.log(error);
          });

        if (Boolean(broadcastID)) {
          axios({
            url: "/broadcast/get/campaign/" + agentID + "?sbcid=" + broadcastID,
            method: "GET",
            authToken: "Bearer " + response.data.token,
          })
            .then((response) => {
              if (response.status) {
                apiComplete(1);
                setFormData(objectFromResponse(response.data));
                axios({
                  url:
                    "/broadcast/time?schedule_date=" +
                    response.data.scheduled_date,
                  method: "GET",
                  authToken: "Bearer " + response.data.token,
                }).then((response) => {
                  if (response.status) {
                    setScheduledTime(response.data);
                  } else {
                    dispatch(alertError(response.errorMessage));
                  }
                });
                axios({
                  url:
                    "/wa/template/variable/" +
                    agentID +
                    "/" +
                    response.data.template_id,
                  method: "GET",
                  authToken: "Bearer " + response.data.token,
                }).then((response) => {
                  if (response.status) {
                    apiComplete(1);
                    setTemplateData(response);
                  } else {
                    dispatch(alertError(response.message.displayMessage));
                  }
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          apiComplete();
        }
      }
    });
  }, [broadcastID, loader, memberID, axios, agentID, dispatch, accessAxios]);

  return (
    <React.Fragment>
      <PageHeader
        pageName="Broadcast Details"
        breadcrumbs={[
          {
            name: "Broadcast",
            location: `broadcast-list?agent_id=${agentID}&member_id=${memberID}`,
          },
        ]}
        memberID={memberID}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        template={template}
        groupList={groupList}
        scheduledTime={scheduledTime}
        templateData={templateData}
        setTemplateData={setTemplateData}
        setScheduledTime={setScheduledTime}
        authToken={authToken}
        agentID={agentID}
      />
    </React.Fragment>
  );
}
