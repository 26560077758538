import React, { useEffect, useState } from "react";
import { useLoader, useAxios, usePermissions } from "../../custom hooks";
import { Loader, PageHeader } from "../../custom components";
import Form from "./business details components/Form";
import {
  businessDetailsObject,
  objectFromResponseData,
} from "./business details components/businessDetailsObject";

export default function BusinessDetails(props) {
  const loader = useLoader();
  const axios = useAxios();
  const pagePermissions = usePermissions(props.pageID);

  const [formData, setFormData] = useState(businessDetailsObject);
  const [botList, setbotList] = useState([]);
  const [planList, setPlanList] = useState([]);

  const businessID = props.match.params.businessID || "";

  useEffect(() => {
    loader.start(3);

    axios({
      url: "/plans",
    })
      .then((response) => {
        loader.apiComplete();

        if (response.status) {
          setPlanList(response.data);
        }
      })
      .catch((error) => console.log(error));

    if (Boolean(businessID)) {
      axios({
        url: "/business/" + businessID,
      })
        .then((response) => {
          loader.apiComplete();

          if (response.status) {
            setFormData(objectFromResponseData(response.data));
          }
        })
        .catch((error) => console.log(error));

      axios({
        url: "/client/agents/" + businessID,
      })
        .then((response) => {
          loader.apiComplete();
          if (response.status) {
            setbotList(response.data);
          }
        })
        .catch((error) => console.log(error));
    } else {
      loader.apiComplete(2);
    }
  }, [axios, loader, businessID]);

  return (
    <Loader>
      <PageHeader
        pageName="Business Details"
        breadcrumbs={[{ name: "Businesses", location: "businesses" }]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        pagePermissions={pagePermissions}
        botList={botList}
        planList={planList}
      />
    </Loader>
  );
}
