import React, { useState, useEffect } from "react";

import { Formik } from "formik";

import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Grid, Button } from "@material-ui/core";

import {
  alertError,
  alertSuccess,
} from "../../../../redux/actions/alertActions";

import FormDisplay from "./FormDisplay";
import validationSchema from "./validationSchema";

import { DeleteDialog, Paper } from "../../../../custom components";

import WhatsappPreview from "../../whatsapp preview/WhatsappPreview";
import { objectFromFormData } from "./broadcastDetailsObject";
import { useAxios } from "../../../../custom hooks";
import { Delete, Edit, Save, Add } from "@material-ui/icons";

export default function Form({
  formData,
  setFormData,
  userList,
  agentID,
  template,
  scheduledTime,
  setTemplateData,
  templateData,
  setScheduledTime,
  groupList,
  authToken,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState(false);
  const [editState, setEditState] = useState(true);

  var memberID = localStorage.getItem("member_id")
    ? localStorage.getItem("member_id")
    : "";

  const axios = useAxios({
    baseURL: "adminV2",
    customAuthToken: "Bearer " + authToken,
  });

  useEffect(() => {
    if (Boolean(formData.ID)) {
      setEditState(false);
    }
  }, [formData.ID]);

  const handleDelete = () => {
    axios({
      url: "/broadcast/delete/campaign/" + formData.ID,
      method: "DELETE",
    }).then((res) => {
      if (res.status) {
        dispatch(alertSuccess(res.message.displayMessage));
        history.push({
          pathname: "/broadcast-list",
          search: "?agent_id=" + agentID + "&member_id=" + memberID,
        });
      } else {
        dispatch(alertError(res.message));
      }
    });
  };

  const handleCancel = (formikProps) => {
    if (
      !window.confirm(
        "Selecting OK, will reset the form and redirect you to the broadcast list page. Are you sure want to do it ?"
      )
    ) {
      return;
    } else {
      formikProps.handleReset();
      history.push({
        pathname: "/broadcast-list",
        search: "?agent_id=" + agentID + "&member_id=" + memberID,
      });
    }
  };

  const saveAsDraft = (values) => {
    var data = objectFromFormData(values, "draft");
    axios({
      url: "/broadcast/create/campaign/" + agentID,
      method: "POST",
      data: data,
    }).then((res) => {
      if (res.status) {
        if (values.ID) {
          dispatch(alertSuccess(res.message.displayMessage));
          setFormData(values);
          setEditState(false);
        } else {
          history.push({
            pathname: "/broadcast-details/" + res.data.sbcid,
            search: "?agent_id=" + agentID + "&member_id=" + memberID,
          });
          setEditState(false);
        }
      } else {
        dispatch(alertError(res.message));
      }
    });
  };
  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var data = objectFromFormData(formData, "pending");
        if (formData.ID) {
          axios({
            url: "/broadcast/update/campaign/" + formData.ID,
            method: "PUT",
            data: data,
          }).then((res) => {
            if (res.status) {
              dispatch(alertSuccess(res.message.displayMessage));
              setSubmitting(false);
              setEditState(false);

              setFormData(formData);
              if (formData.whenToBroadcast === "later") {
                history.push({
                  pathname: "/broadcast-list",
                  search: "?agent_id=" + agentID + "&member_id=" + memberID,
                });
              }
            } else {
              dispatch(alertError(res.message.displayMessage));
            }
          });
        } else {
          axios({
            url: "/broadcast/create/campaign/" + agentID,
            method: "POST",
            data: data,
          }).then((res) => {
            if (res.status) {
              dispatch(alertSuccess(res.message.displayMessage));
              setSubmitting(false);

              setEditState(false);
              history.push({
                pathname: "/broadcast-list",
                search: "?agent_id=" + agentID + "&member_id=" + memberID,
              });
            } else {
              dispatch(alertError(res.message.displayMessage));
            }
          });
        }
      }}
    >
      {(formikProps) => (
        <Grid alignItems="stretch" container spacing={2}>
          <Grid item xs={12} sm={8}>
            <form noValidate onSubmit={formikProps.handleSubmit}>
              <FormDisplay
                formikProps={formikProps}
                editState={editState}
                setEditState={setEditState}
                userList={userList}
                template={template}
                setTemplateData={setTemplateData}
                templateData={templateData}
                scheduledTime={scheduledTime}
                setScheduledTime={setScheduledTime}
                groupList={groupList}
                authToken={authToken}
                memberID={memberID}
                agentID={agentID}
              />

              <Paper>
                {Boolean(formikProps.values.ID) && (
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{ background: "#d32f2f", color: "#fff" }}
                      onClick={() => setDialogState(true)}
                    >
                      <Delete size="small" style={{ fontSize: "18px" }} />
                      &nbsp;Delete Broadcast
                    </Button>
                  </Grid>
                )}

                {Boolean(editState) &&
                  formikProps.values.whenToBroadcast !== "now" &&
                  formikProps.values.broadcastStatus !== "pending" && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          formikProps.dirty
                            ? formikProps.isSubmitting || !formikProps.isValid
                            : !formikProps.dirty
                        }
                        onClick={() => saveAsDraft(formikProps.values)}
                      >
                        <Save size="small" style={{ fontSize: "18px" }} />{" "}
                        &nbsp; Draft
                      </Button>
                    </Grid>
                  )}

                <Grid item xs />

                {Boolean(editState) ? (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        formikProps.dirty
                          ? formikProps.isSubmitting || !formikProps.isValid
                          : !formikProps.dirty
                      }
                      onClick={formikProps.handleSubmit}
                    >
                      <Add size="small" style={{ fontSize: "18px" }} /> &nbsp;
                      Create Broadcast
                    </Button>
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setEditState(true)}
                    >
                      <Edit size="small" style={{ fontSize: "18px" }} /> &nbsp;
                      Edit
                    </Button>
                  </Grid>
                )}

                {Boolean(editState) && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (Boolean(formikProps.values.ID)) {
                          setEditState(false);
                          handleCancel(formikProps);
                        } else {
                          history.goBack();
                        }
                      }}
                    >
                      {Boolean(formikProps.values.ID) ? "Cancel" : "Back"}
                    </Button>
                  </Grid>
                )}
              </Paper>
            </form>
          </Grid>
          <Grid
            style={{ justifyContent: "center", margin: "auto" }}
            container
            item
            xs={12}
            sm={4}
          >
            <WhatsappPreview templateData={templateData} />
          </Grid>

          <DeleteDialog
            dialogState={dialogState}
            setDialogState={setDialogState}
            handleAgree={handleDelete}
          />
        </Grid>
      )}
    </Formik>
  );
}
