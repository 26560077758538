import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import { Paper, ComponentHeader } from "../../../custom components";

function BusinessAdminPermissions({
  pagePermissions,
  setValues,
  editState,
  permissionPages,
  permissions,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader headerName="Business Admin Permissions" />
      </Grid>

      <Grid item xs={12}>
        <Table>
          <TableHead style={{ background: "#bbd4ff" }}>
            <TableRow>
              <TableCell>Pages</TableCell>
              <TableCell align="center">Access Permissions</TableCell>
              <TableCell align="center">Read Permissions</TableCell>
              <TableCell align="center">Update Permissions</TableCell>
              <TableCell align="center">Create Permissions</TableCell>
              <TableCell align="center">Delete Permissions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {permissionPages.map((permissionPage) => {
              const permissionValue = pagePermissions.find(
                (page) => page.page_id === permissionPage._id
              );

              return (
                <TableRow key={permissionPage._id}>
                  <TableCell>{permissionPage.page_name}</TableCell>

                  <TableCell align="center">
                    <Checkbox
                      name={permissionPage._id + ".access"}
                      checked={
                        permissionValue === undefined
                          ? false
                          : permissionValue.access
                      }
                      onChange={permissionsChange}
                      disabled={!(editState && permissions.update)}
                    />
                  </TableCell>

                  <TableCell align="center">
                    <Checkbox
                      name={permissionPage._id + ".read"}
                      checked={
                        permissionValue === undefined
                          ? false
                          : permissionValue.read
                      }
                      onChange={permissionsChange}
                      disabled={
                        !(
                          editState &&
                          permissionValue?.access &&
                          permissions.update
                        )
                      }
                    />
                  </TableCell>

                  {["update", "create", "delete"].map((category) => (
                    <TableCell align="center" key={category}>
                      <Checkbox
                        name={permissionPage._id + "." + category}
                        checked={
                          permissionValue === undefined
                            ? false
                            : permissionValue[category]
                        }
                        onChange={permissionsChange}
                        disabled={
                          !(
                            editState &&
                            permissionValue?.read &&
                            permissions.update
                          )
                        }
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Paper>
  );

  function permissionsChange({ target }) {
    const { name, checked } = target;
    const [pageID, type] = name.split(".");

    const pageIndex = pagePermissions.findIndex(
      (page) => page.page_id === pageID
    );

    if (pageIndex === -1) {
      setValues((prevValue) => ({
        ...prevValue,
        pagePermissions: [
          ...prevValue.pagePermissions,
          {
            page_id: pageID,
            access: true,
            create: false,
            update: false,
            read: false,
            delete: false,
          },
        ],
      }));
    } else {
      setValues((prevValue) => ({
        ...prevValue,
        pagePermissions: prevValue.pagePermissions.map((page) => {
          if (page.page_id === pageID) {
            return newPagePermissions(page);
          }

          return page;
        }),
      }));
    }

    function newPagePermissions(permissions) {
      permissions = {
        ...permissions,
        [type]: checked,
      };

      return {
        page_id: permissions.page_id,
        access: permissions.access,
        read: permissions.access && permissions.read,
        create: permissions.access && permissions.read && permissions.create,
        update: permissions.access && permissions.read && permissions.update,
        delete: permissions.access && permissions.read && permissions.delete,
      };
    }
  }
}

export default React.memo(BusinessAdminPermissions);
