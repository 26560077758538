import * as Yup from "yup";
import channel from "../../../constants/channelID";

const validationSchema = Yup.object().shape({
  botName: Yup.string()
    .max(50, "Bot Name too long")
    .required("Bot Name is required"),
  botChannel: Yup.string().required("Please select a channel"),
  businessID: Yup.string().required("Please select a business"),
  botServiceID: Yup.string().required("Please select a bot service"),
  botStatus: Yup.string().required("Agent Status Required"),
  botPlanID: Yup.string().required("Plan ID Required"),
  whatsappServer: Yup.string(),
  authentication: Yup.object()
    .when("botChannel", {
      is: (botChannel) =>
        botChannel === channel.facebook || botChannel === channel.instagram,
      then: Yup.object().shape({
        accessToken: Yup.string(),
        pageID: Yup.string().required("Page ID is required"),
        pageName: Yup.string().required("Page Name is required"),
        secret: Yup.string(),
        verify: Yup.string(),
      }),
    })
    .when("botChannel", {
      is: (botChannel) => botChannel === channel.whatsapp,
      then: Yup.object()
        .when("whatsappServer", {
          is: (whatsappServer) => whatsappServer === "normal",
          then: Yup.object().shape({
            accessToken: Yup.string().required("Acess Token is required"),
            phoneNumber: Yup.string()
              .matches(/^[0-9]{10,12}$/, "Please enter a valid mobile number")
              .required("Whatsapp Number is required"),
            adminUsername: Yup.string().required("Admin Username is required"),
            adminPassword: Yup.string().required("Admin Password is required"),
            URL: Yup.string().required("URL is required"),
            namespace: Yup.string().required("Namespace is required"),
            wabaID: Yup.string().required("Whatsapp Business ID is required"),
          }),
        })
        .when("whatsappServer", {
          is: (whatsappServer) => whatsappServer === "karix_rcm",
          then: Yup.object().shape({
            accessToken: Yup.string().required("Acess Token is required"),
            phoneNumber: Yup.string()
              .matches(/^[0-9]{10,12}$/, "Please enter a valid mobile number")
              .required("Whatsapp Number is required"),
            adminUsername: Yup.string().required("Admin Username is required"),
            adminPassword: Yup.string().required("Admin Password is required"),
            URL: Yup.string().required("URL is required"),
            host: Yup.string().required("Host is required"),
            wabaID: Yup.string().required("Whatsapp Business ID is required"),
          }),
        }),
    }),
});

export default validationSchema;
