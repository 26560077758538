import React from "react";
import {
  TextField,
  Paper,
  ComponentHeader,
  TextFieldWithCopy,
} from "../../../custom components";
import channel from "../../../constants/channelID";
import { Grid, MenuItem } from "@material-ui/core";

export default function AuthenticationDisplay({
  formikProps,
  editState,
  permissions,
}) {
  if (formikProps.values.botChannel === channel.whatsapp) {
    return (
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader headerName="WhatsApp Authentication Details" />
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            required
            disabled={!editState}
            formikProps={formikProps}
            label="Whatsapp Service"
            name="whatsappServer"
          >
            <MenuItem value={"normal"}>Ameyo</MenuItem>
            <MenuItem value={"karix_rcm"}>Karix</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextFieldWithCopy
            required
            label={
              formikProps.values.whatsappServer === "normal"
                ? "Namespace"
                : "Host"
            }
            name={
              formikProps.values.whatsappServer === "normal"
                ? "authentication.namespace"
                : "authentication.host"
            }
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldWithCopy
            required
            label="URL"
            name="authentication.URL"
            disabled={!editState}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldWithCopy
            required
            label="Phone Number"
            name="authentication.phoneNumber"
            disabled={!editState}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldWithCopy
            required
            label="WhatsApp Access Token"
            name="authentication.accessToken"
            disabled={!editState}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldWithCopy
            required
            label="Whatsapp Business ID"
            name="authentication.wabaID"
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldWithCopy
            required
            label="Admin Username"
            name="authentication.adminUsername"
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldWithCopy
            required
            label="Admin Password"
            name="authentication.adminPassword"
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>
      </Paper>
    );
  }

  if (
    formikProps.values.botChannel === channel.facebook ||
    formikProps.values.botChannel === channel.instagram
  ) {
    return (
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            headerName={
              formikProps.values.botChannel === channel.facebook
                ? "Facebook Authentication Details"
                : "Instagram Authentication Details"
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Page ID"
            name="authentication.pageID"
            required
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Page Name"
            name="authentication.pageName"
            required
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Bot Verification Name"
            name="authentication.verify"
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="App Secret Key"
            name="authentication.secret"
            formikProps={formikProps}
            disabled={!editState}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label={
              formikProps.values.botChannel === channel.facebook
                ? "Facebook Access Token"
                : "Instagram Access Token"
            }
            name="authentication.accessToken"
            disabled={!editState}
            formikProps={formikProps}
          />
        </Grid>
      </Paper>
    );
  }

  return "";
}
